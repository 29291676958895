import React from "react"
import { navigate } from "gatsby-link"

const routes = require("../../../types/routes")

class SignUpFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      message: "Please enter a valid work email",
    }
    this.component = null
    this.routes = routes
    this.form = React.createRef()
    this.input = React.createRef()
    this.button = React.createRef()
  }

  componentDidMount() {
    if (this.props.mode && this.props.mode === "light") {
      this.form.current.classList.add("light")
    }
  }

  onGetCookies(string) {
    return Object.fromEntries(
      document.cookie.split("; ").map(v => v.split("=").map(decodeURIComponent))
    )[string]
  }

  onButtonStateLoading() {
    this.button.current.classList.add("spinner", "loading")
  }

  onButtonStateDefault() {
    this.button.current.classList.remove("spinner", "disabled", "loading")
  }

  onValidate() {
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regex = RegExp(emailPattern)
    const valid = regex.test(this.input.current.value)
    const invalidDomains = [
      "gmail.com",
      "hotmail.com",
      "live.com",
      "live.co.uk",
      "yahoo.com",
      "icloud.com",
      "outlook.com",
      "hey.com",
      "aol.com",
    ]
    const isDomainValid = invalidDomains.every(
      substring => this.input.current.value.indexOf(substring) === -1
    )
    if (isDomainValid === false) {
      this.onMarkAsInvalid(this.input.current)
      return false
    }
    this.setState({ valid })
    valid
      ? this.onMarkAsValid(this.input.current)
      : this.onMarkAsInvalid(this.input.current)
    return valid
  }

  onMarkAsInvalid(message) {
    this.setState({ message: message })
    this.input.current.classList.add("error")
    this.form.current.classList.add("error")
    this.button.current.classList.add("disabled")
  }

  onMarkAsValid() {
    this.message = "Please enter a valid work email"
    this.input.current.classList.remove("error")
    this.form.current.classList.remove("error")
    this.button.current.classList.remove("disabled")
  }

  onEnterKeypress(e) {
    this.onMarkAsValid()
    if (e.key === "Enter") {
      e.preventDefault()
      this.onSubmitForm(e)
    }
  }

  onSubmitForm(e) {
    this.onButtonStateLoading()
    if (this.onValidate()) {
      const hutk = this.onGetCookies("hubspotutk")
      const data = {
        fields: [{ name: "email", value: this.input.current.value }],
        context: {
          hutk: hutk,
          pageUri: window.location.href,
        },
      }
      this.ajax(
        this.form.current.method,
        this.form.current.action,
        data,
        data => {
          this.onButtonStateDefault()
          navigate(
            `${this.routes.routes.getStarted}?email=${this.input.current.value}`,
            {}
          )
        },
        (error, data) => {
          const resp = JSON.parse(data)
          const message = resp.errors[0].message
          this.onButtonStateDefault()
          this.onMarkAsInvalid(message)
        }
      )
    } else {
      setTimeout(() => {
        this.onButtonStateDefault()
      }, 500)
    }
  }

  ajax(method, url, data, success, error) {
    var xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        success(xhr.response, xhr.responseType)
      } else {
        error(xhr.status, xhr.response, xhr.responseType)
      }
    }
    xhr.send(JSON.stringify(data))
  }

  render() {
    return (
      <form
        className={"sign-up-form"}
        ref={this.form}
        method={"post"}
        action={
          "https://api.hsforms.com/submissions/v3/integration/submit/8614381/6de56ecd-f7e3-481e-aac8-4a08cfe2733e"
        }
      >
        <div className={"group"}>
          <input
            type={"text"}
            name="email"
            placeholder={"Enter your work email"}
            onKeyDown={e => this.onEnterKeypress(e)}
            ref={this.input}
          />
          <button
            type={"button"}
            ref={this.button}
            onClick={e => this.onSubmitForm(e)}
          >
            REQUEST A DEMO
            <div className={"spinner"}></div>
          </button>
        </div>
        <div className={"error-text"}>{this.message}</div>
      </form>
    )
  }
}

export default SignUpFormComponent
