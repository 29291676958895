import React from "react"
import SignUpFormComponent from "../components/sign-up-form.component"

class CTA extends React.Component {
  render() {
    return <div />
  }
}

export default CTA
