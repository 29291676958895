exports.routes = {
  contactUs: "/contact-us/",
  cookiePolicy: "/cookie-policy/",
  privacyPolicy: "/privacy-policy/",
  termsAndConditions: "/terms-and-services/",
  compliance: "/solutions/compliance/",
  procurement: "/solutions/procurement/",
  operationalResilience: "/solutions/operational-resilience",
  supplyChainMapping: "/solutions/supply-chain-mapping/",
  pricing: "/pricing/",
  whitePaper: "/2021-white-paper/",
  whitePaperSuccess: "/2021-white-paper/success/",
  getStarted: "/get-started/",
  getStartedSuccess: "/get-started/success",
  company: "/company/",
  blog: "/blog/",
}
